:root {
  --main-app-bg-color: #F7F7F7;
  --main-link-color: #55B8C5;
  --main-sidebar-color: #40C9D5;
  --main-focus-color: #69C7D3;
  --main-font-family: 'DM Sans', sans-serif;
  --main-font-family-color: #303857;
  --secondary-font-family-color: #50597B;
  --third-color: #4F597A;
}

.app {
  background-color: var(--main-app-bg-color);
}

.frame {
  margin-left: calc(86px + 2%);
  margin-right: 2%;
  padding-bottom: 5%;
}

.title {
  font-family: var(--main-font-family);
  font-size: 24px;
  color: var(--main-font-family-color);
  font-weight: bold;
}


.subtitle {
  font-family: var(--main-font-family);
  font-size: 16px;
  color: var(--main-font-family-color);
  letter-spacing: 0.14px;

}

/* NOT From desings -----*/

.subtitle-light {
  font-family: var(--main-font-family);
  font-weight: lighter;
  font-size: 20px;
  color: #564c60;
}

.info-text {
  font-family: var(--main-font-family);
  font-size: 16px;
  color: #564c60;
}

.info-text-small {
  font-family: var(--main-font-family);
  font-size: 14px;
  color: #564c60;
}

/* NOT From desings -----*/

/* From desings */

.standard-text {
  font-family: var(--main-font-family);
  font-size: 14px;
  color: var(--secondary-font-family-color);
}

/* Link for all app*/

.simple-link {
  font-family: var(--main-font-family);
  font-weight: 800;
  font-size: 14px;
  color: var(--main-link-color);
}

/* Sessions (login, register, forget password) */

.login-frame {
  border: 1px solid white;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 12px;
  opacity: 0.85;
  padding: 10px 50px;
  width: 30rem;
  height: 30rem;
}

.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}

/* @media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
} */


/* General inputs white box */
.white-box {
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 12px;
}

/* Drop file box */

.wrapper {
  width: 370px;
  height: 165px;
  text-align: center;
  padding: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 1px dashed #C2C5D1;
  width: 100%;
  margin: auto;
}

/* Table */

.main-table tbody tr:nth-child(odd) {
  background-color: #FFFFFF;
}

.main-table tbody tr:nth-child(even) {
  background-color: #FFFFFF;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--main-focus-color) !important;
  font-family: var(--main-font-family) !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--secondary-font-family-color) !important;
  font-family: var(--main-font-family) !important;
}

/* Table */
.main-table {
  border-collapse: separate !important;
  border-spacing: 0 6px !important;
}

th {
  background-color: var(--main-app-bg-color) !important;
  color: var(--main-font-family-color) !important;
  font-family: var(--main-font-family) !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

td {
  border: 1px solid var(--main-app-bg-color);
  padding: 10px !important;
  color: var(--secondary-font-family-color) !important;
  font-family: var(--main-font-family) !important;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-numbers {
  font-weight: bold;
  text-align: center;
  width: 30px;
  font-family: var(--main-font-family);
  color: var(--secondary-font-family-color);
  background-color: #E6E7EA;
  border-radius: 200px;
}

.pagination-buttons {
  font-weight: bold;
  text-align: center;
  width: 30px;
  font-family: var(--main-font-family);
  color: var(--secondary-font-family-color);
  background-color: #E6E7EA;
  border-radius: 200px;
  border: none;
}

.table-icons {
  color: var(--third-color);
  cursor: pointer;
  vertical-align: baseline;
}

.table-icons:hover {
  color: var(--main-link-color) !important;
}

/* Buttons */


.button-gray {
  cursor: pointer;
  font-size: 14px !important;
  background: #E6E7EA66 !important;
  border: 1px solid #E6E7EA !important;
  border-radius: 6px !important;
  color: #50597B !important;
  text-transform: none !important;
}

.button-gray.active {
  cursor: pointer;
  font-size: 14px !important;
  background: #40C9D5 !important;
  border: 1px solid #40C9D5 !important;
  border-radius: 6px !important;
  color: #50597B !important;
  text-transform: none !important;

}

.button-gray-active {
  cursor: pointer;
  font-size: 14px !important;
  background: #40C9D5 !important;
  border: 1px solid #40C9D5 !important;
  border-radius: 6px !important;
  color: #50597B !important;
  text-transform: none !important;

}

/* Template preview */

.template-preview-box {
  background: #EFF2FC;
  border-radius: 2px;
}

.template-preview-inner-box {
  border: 1px dashed #515978;
  border-radius: 2px;
  text-transform: uppercase;
}

/* PDFS */
.txt {
  white-space: nowrap;
  text-align: center;
}

#f0 {
  font-family: sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ff0;
  src: url("0.otf");
}

#f1 {
  font-family: ff0, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ff1;
  src: url("1.otf");
}

#f2 {
  font-family: ff1, sans-serif;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: ff2;
  src: url("2.ttf");
}

#f3 {
  font-family: ff2, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ff3;
  src: url("3.otf");
}

#f4 {
  font-family: ff3, monospace;
  font-weight: normal;
  font-style: normal;
}

#f5 {
  font-family: sans-serif;
  font-weight: bold;
  font-style: normal;
}

#f6 {
  font-family: sans-serif;
  font-weight: normal;
  font-style: normal;
}

.item-nested {
  border-style: dashed;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  border-color: #D5D7E2;
}