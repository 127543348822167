#header {
    position: fixed;
    top: 0;
    z-index: 1200;
    height: 100vh;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#header .pro-sidebar {
    width: 170px;
    min-width: 170px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 64px;
}

#header .pro-sidebar.collapsed {
    width: 86px;
    min-width: 86px;
}

#header .pro-sidebar-inner {
    background-color: white;
}

#header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
}

#header .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
    border: none;
}

#header .pro-sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-footer {
    border: none;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #9BA1AA;
    font-weight: 500;
    line-height: 28px;
    margin: 10px 0px;
    padding: 0px;
}

#header .pro-sidebar .pro-menu.shaped .pro-menu-item .pro-inner-item :active {
    background-color: #f1f4fa;
}

#header .pro-sidebar .pro-menu.shaped .pro-menu-item .pro-inner-item :hover {
    background-color: #f1f4fa;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-item-content :hover {
    background-color: transparent;
}

.link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 19px;
}

.custom-nav-links {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 10px;
    color: #50597B;
    text-decoration: none;
    padding: 10px;
}
  
.custom-nav-links-active {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: var(--main-font-family);
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
    text-decoration: none;
    background-color: var(--main-sidebar-color);
    padding: 10px;
}

.sidebar-icons {
    vertical-align: sub;
}

.custom-nav-links:hover {
    color: #FFFFFF;
    background-color: var(--main-sidebar-color);
}

.custom-nav-links:hover .sidebar-typo .sidebar-icons {
    filter: brightness(0) invert(1);
}

.custom-nav-links-active:hover {
    color: #FFFFFF;
}

.custom-nav-links-active .sidebar-typo .sidebar-icons {
    filter: brightness(0) invert(1);
}


@media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }
}